import { getCookie, setCookie } from 'cookies-next';

import { sum } from './array';

const COOKIE_VENDOR_PREFIX = 'pr';
export const EXPERIMENT_ALPHABET = [...'abcdefghijklmnopqrstuvwxyz'];

/**
    // Incremental id. Last one used should be checked history.
    id: '00001',
    description: 'Change coupons area text',
    startDate: new Date('04/13/2024'),
    endDate: new Date('04/23/2024'),
    // Each array item represents a variant. Number refers to the percentage
    // chance each item has to be selected.
    weights: [20, 80],
    redistributions: [
      {
        startDate: new Date('04/15/2024'),
        endDate: new Date('04/25/2024'),
        variantToRedistribute: 'a',
        weights: [10, 90]
      }
    ],
 */
export const CURRENT_ACTIVE_AB_EXPERIMENTS = [];
const WEIGHT_LIMIT = 100;

const appendExperimentCookie = ({
  endDate,
  expId,
  version,
  variant = null,
}) => {
  const opts = {
    expires: endDate,
    sameSite: 'lax',
    secure: true,
  };

  setCookie(getVariantCookieKey(expId), variant, opts);
  setCookie(getExpRedistributionVersionCookieKey(expId), version, opts);
};

const calcWeightOfVariantToRedistribute = (oldWeight, updatedWeight) =>
  (updatedWeight / oldWeight) * 100;

export const getVariantCookieKey = (expId) =>
  `${COOKIE_VENDOR_PREFIX}_exp_${expId}_var`;

export const getExpRedistributionVersionCookieKey = (expId) =>
  `${COOKIE_VENDOR_PREFIX}_exp_${expId}_vsn_var`;

export const getExpDistributionVersion = (expMeta) =>
  expMeta.redistributions.length;

export const getExpMetaById = (expId) =>
  CURRENT_ACTIVE_AB_EXPERIMENTS.find((item) => item.id === expId);

/**
 * When we reset the user-base of a specific variant to decrease your weight,
 * because we already being applying the experiment, we necessary need to
 * calculate a particular proportional weight for each variant to apply for
 * users that initially receive the specified variant to keeping the desired
 * final user distribution.
 */
export const getExpWeightsToRedistribute = (expMeta) => {
  if (expMeta.redistributions.length === 0) {
    return expMeta.weights;
  }

  const oldDistribution = expMeta.redistributions.at(-2) || expMeta;
  const updatedDistribution = expMeta.redistributions.at(-1);
  const variantIndicatedToRedistributeIndex = EXPERIMENT_ALPHABET.indexOf(
    updatedDistribution.variantToRedistribute
  );
  const expectedWeightOfIndicatedVariantToRedistribute =
    updatedDistribution.weights[variantIndicatedToRedistributeIndex];
  const updatedWeightOfVariantToRedistribute =
    calcWeightOfVariantToRedistribute(
      oldDistribution.weights[variantIndicatedToRedistributeIndex],
      expectedWeightOfIndicatedVariantToRedistribute
    );
  const remainingWeight = WEIGHT_LIMIT - updatedWeightOfVariantToRedistribute;
  const totalWeightWithoutRedistributedVariant =
    sum(updatedDistribution.weights) -
    expectedWeightOfIndicatedVariantToRedistribute;

  return updatedDistribution.weights.map((weight, index) => {
    if (variantIndicatedToRedistributeIndex === index) {
      return updatedWeightOfVariantToRedistribute;
    }

    return (weight * remainingWeight) / totalWeightWithoutRedistributedVariant;
  });
};

export const getLastAvailableDistribution = (expMeta) => {
  if (expMeta.redistributions.length === 0) {
    return expMeta;
  }

  return expMeta.redistributions.at(-1);
};

export const getServerExperimentProps = ({ expId, req, res }) => ({
  [expId]: getCookie(getVariantCookieKey(expId), { req, res }) || null,
});

export const getVariant = (weights) => {
  const totalWeight = sum(weights);

  if (totalWeight !== WEIGHT_LIMIT) {
    throw new Error(
      `The sum of 'variants' does not equals ${WEIGHT_LIMIT}. Please provide a valid array containing items that summed equals exactly ${WEIGHT_LIMIT}. This is needed for the experiment to be distributed correctly.`
    );
  }

  let n = Math.random() * totalWeight;
  let i = null;

  weights.find((_, index) => {
    n = n - weights[index];
    i = n <= 0 && index;
    return n <= 0;
  });

  return EXPERIMENT_ALPHABET[i];
};

export const setExperimentCookie = (expMeta) => {
  const distribution = getLastAvailableDistribution(expMeta);

  appendExperimentCookie({
    endDate: distribution.endDate,
    expId: expMeta.id,
    variant: getVariant(distribution.weights),
    version: getExpDistributionVersion(expMeta),
  });
};

export const redistributeExperimentCookie = (expMeta) => {
  const distribution = getLastAvailableDistribution(expMeta);

  appendExperimentCookie({
    endDate: distribution.endDate,
    expId: expMeta.id,
    variant: getVariant(getExpWeightsToRedistribute(expMeta)),
    version: getExpDistributionVersion(expMeta),
  });
};

export const renewExperimentCookie = (activeVariant, expMeta) => {
  const distribution = getLastAvailableDistribution(expMeta);

  appendExperimentCookie({
    endDate: distribution.endDate,
    expId: expMeta.id,
    variant: activeVariant,
    version: getExpDistributionVersion(expMeta),
  });
};
